import React, { useState } from "react";
export default function WorkingList() {
  const [workingList] = useState();
  if (!workingList)
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>Coming soon..</h1>
        </section>
      </div>
    );
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Production List
          <small>{/* Can add description here */}</small>
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="dataTables_wrapper form-inline dt-bootstrap">
                  <div className="row">
                    <div className="col-sm-12">
                      <table className="table table-bordered table-striped table-hover dataTable">
                        <thead>
                          <tr role="row">
                            <th className="sorting_asc">Name</th>
                            <th className="sorting">Description</th>
                            <th className="sorting">Total</th>
                            <th className="sorting">Finish</th>
                            <th className="sorting">Remaining</th>
                            <th className="sorting">Start</th>
                            <th className="sorting">Due</th>
                            <th className="sorting">End</th>
                            <th className="sorting">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="odd">
                            <td>November Week 1</td>
                            <td>3 console table, 20 medium vase</td>
                            <td className="sorting_1">23</td>
                            <td>12</td>
                            <td>11</td>
                            <td>01 Nov 2020</td>
                            <td>07 Nov 2020</td>
                            <td>06 Nov 2020</td>
                            <td>Done</td>
                          </tr>
                          <tr role="row" className="even" style={{ backgroundColor: "rgb(255 217 170)" }}>
                            <td>Blooming Vase #368</td>
                            <td>Repeat order Vanya</td>
                            <td className="sorting_1">23</td>
                            <td>12</td>
                            <td>11</td>
                            <td>05 Nov 2020</td>
                            <td>08 Nov 2020</td>
                            <td></td>
                            <td>Overdue</td>
                          </tr>
                          <tr className="odd">
                            <td>R&amp;D #143</td>
                            <td>For new puzzle table</td>
                            <td className="sorting_1">5</td>
                            <td>5</td>
                            <td></td>
                            <td>10 Nov 2020</td>
                            <td>17 Nov 2020</td>
                            <td>17 Nov 2020</td>
                            <td>Done</td>
                          </tr>
                          <tr className="even">
                            <td>Bersihin Kebun</td>
                            <td>Untuk bangun gudang</td>
                            <td className="sorting_1"></td>
                            <td></td>
                            <td></td>
                            <td>10 Nov 2020</td>
                            <td>12 Nov 2020</td>
                            <td>12 Nov 2020</td>
                            <td>Done</td>
                          </tr>
                          <tr className="odd">
                            <td>Bangun Mes</td>
                            <td>Kamar dan toilet</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>10 Nov 2020</td>
                            <td>12 Nov 2020</td>
                            <td>12 Nov 2020</td>
                            <td>Upcoming</td>
                          </tr>
                          <tr className="odd">
                            <td>November Week 1</td>
                            <td>3 console table, 20 medium vase</td>
                            <td className="sorting_1">23</td>
                            <td>12</td>
                            <td>11</td>
                            <td>01 Nov 2020</td>
                            <td>07 Nov 2020</td>
                            <td>06 Nov 2020</td>
                            <td>Done</td>
                          </tr>
                          <tr className="even">
                            <td>Molten Glass</td>
                            <td>Pesenan Yola</td>
                            <td className="sorting_1">2</td>
                            <td>1</td>
                            <td>1</td>
                            <td>05 Nov 2020</td>
                            <td>08 Nov 2020</td>
                            <td></td>
                            <td>In Progress</td>
                          </tr>
                          <tr className="odd">
                            <td>R&amp;D #143</td>
                            <td>For new puzzle table</td>
                            <td className="sorting_1">5</td>
                            <td>5</td>
                            <td></td>
                            <td>10 Nov 2020</td>
                            <td>17 Nov 2020</td>
                            <td>17 Nov 2020</td>
                            <td>Done</td>
                          </tr>
                          <tr className="even">
                            <td>Bersihin Kebun</td>
                            <td>Untuk bangun gudang</td>
                            <td className="sorting_1"></td>
                            <td></td>
                            <td></td>
                            <td>10 Nov 2020</td>
                            <td>12 Nov 2020</td>
                            <td>12 Nov 2020</td>
                            <td>Done</td>
                          </tr>
                          <tr className="odd">
                            <td>Bangun Mes</td>
                            <td>Kamar dan toilet</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>10 Nov 2020</td>
                            <td>12 Nov 2020</td>
                            <td>12 Nov 2020</td>
                            <td>Upcoming</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
