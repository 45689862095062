import React, { useState } from "react";
export default function Product() {
  const [products] = useState();
  if (!products)
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>Coming soon..</h1>
        </section>
      </div>
    );
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Products
          <small>{/* Can add description here */}</small>
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div id="example1_wrapper" className="dataTables_wrapper foPR-inline dt-bootstrap">
                  <div className="row">
                    <div className="col-sm-12">
                      <table
                        id="example1"
                        className="table table-bordered table-striped table-hover dataTable"
                        role="grid"
                        aria-describedby="example1_info">
                        <thead>
                          <tr role="row">
                            <th className="sorting_asc">Code</th>
                            <th className="sorting">Name</th>
                            <th className="sorting">Description</th>
                            <th className="sorting">Reject</th>
                            <th className="sorting">In Progress</th>
                            <th className="sorting">Finishing</th>
                            <th className="sorting">Ready</th>
                            <th className="sorting">Packed</th>
                            <th className="sorting">Demand</th>
                            <th className="sorting">Remaining</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" className="odd">
                            <td>PR-201103-001</td>
                            <td>Wooden tray finishing 1</td>
                            <td className="sorting_1">Dari RM-20101010-009</td>
                            <td>6</td>
                            <td>7</td>
                            <td>1</td>
                            <td>1</td>
                            <td>19</td>
                            <td>20</td>
                            <td style={{ backgroundColor: "#ffbbbb" }}>1</td>
                          </tr>
                          <tr role="row" className="even">
                            <td>PR-201103-002</td>
                            <td>Wooden tray finishing 2</td>
                            <td className="sorting_1">Dari RM-20101010-009</td>
                            <td>13</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>16</td>
                            <td>1</td>
                            <td></td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>PR-201103-002</td>
                            <td>Wooden tray finishing 3</td>
                            <td className="sorting_1">Dari RM-20101010-009</td>
                            <td>4</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>7</td>
                            <td>10</td>
                            <td style={{ backgroundColor: "#ffbbbb" }}>3</td>
                          </tr>
                          <tr role="row" className="even">
                            <td>PR-201103-002</td>
                            <td>Nina Vas finishing 1</td>
                            <td className="sorting_1">Dari RM-20101010-049</td>
                            <td>2</td>
                            <td>1</td>
                            <td></td>
                            <td>1</td>
                            <td>4</td>
                            <td>1</td>
                            <td></td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>PR-201103-004</td>
                            <td>Nina Vas finishing 2</td>
                            <td className="sorting_1">Dari RM-20101010-049</td>

                            <td>4</td>
                            <td>1</td>
                            <td></td>
                            <td>1</td>
                            <td>6</td>
                            <td>2</td>
                            <td></td>
                          </tr>
                          <tr role="row" className="even">
                            <td>PR-201103-004</td>
                            <td>Terracota (M)</td>
                            <td>Earthenware ceramic</td>

                            <td>6</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                            <td>11</td>
                            <td>15</td>
                            <td style={{ backgroundColor: "#ffbbbb" }}>4</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>PR-201103-004</td>
                            <td>Terracota (L)</td>
                            <td>Earthenware ceramic</td>

                            <td>8</td>
                            <td></td>
                            <td>1</td>
                            <td>1</td>
                            <td>10</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr role="row" className="even">
                            <td>PR-201103-004</td>
                            <td>Teak Wood</td>
                            <td>Wooden teak</td>

                            <td>7</td>
                            <td></td>
                            <td>1</td>
                            <td>2</td>
                            <td>10</td>
                            <td>12</td>
                            <td style={{ backgroundColor: "#ffbbbb" }}>2</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>PR-201103-004</td>
                            <td>Nails</td>
                            <td>Used with hammer</td>

                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>2</td>
                            <td>5</td>
                            <td>2</td>
                            <td></td>
                          </tr>
                          <tr role="row" className="even">
                            <td>PR-201103-004</td>
                            <td>Wood Glue</td>
                            <td>Sticking wood together</td>

                            <td>1</td>
                            <td></td>
                            <td>2</td>
                            <td>2</td>
                            <td>5</td>
                            <td>1</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
