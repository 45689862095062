import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getInventory } from "../services/inventory";
import moment from "moment";

export default function ToolView() {
  const [tool, setTool] = useState();
  const { item_id } = useParams();
  const history = useHistory();
  useEffect(async () => {
    try {
      const { data } = await getInventory(item_id);
      setTool(data.tool);
    } catch (error) {
      alert(error.data);
    }
  }, []);
  if (!tool) return "Loading data...";
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          {tool.item_name}
          <small>{tool.item_code}</small>
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <h4>&nbsp;&nbsp;{tool.item_desc}</h4>
              <table className="table dataTable table-striped table-hover">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Date</th>
                    <th>Transaction</th>
                    <th>Ordered</th>
                    <th>Received</th>
                    <th>Disposed</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {tool.journal_items.map(
                    ({ trs_id, trs_type, trs_code, trs_desc, jitm_date, jitm_notes, acc_type, jitm_qty }, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{moment.unix(jitm_date).format("DD MMMM YYYY")}</td>
                        <td>
                          {trs_type === "purchase-order" && (
                            <a
                              href={`/purchase-order/${trs_id}`}
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push(`/purchase-order/${trs_id}`);
                              }}>
                              {trs_code}
                            </a>
                          )}
                        </td>
                        <td>{acc_type === "ordered" ? (jitm_qty || "").toLocaleString() : ""}</td>
                        <td>{acc_type === "received" ? (jitm_qty || "").toLocaleString() : ""}</td>
                        <td>{acc_type === "disposed" ? (jitm_qty || "").toLocaleString() : ""}</td>
                        <td>{jitm_notes || trs_desc}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
