import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
// import Timeline from "../components/timeline";
import Tabs from "../components/tabs";
import { closePO, getPO, receiveAll, updatePOBilling, updatePOItem } from "../services/purchaseOrder";
import moment from "moment";
import Link from "../components/link";
import { getPaymentAccounts } from "../services/account";
import { uploadReceipt } from "../services/transaction";

const billingTypes = [
  {
    id: "payment",
    title: "Payment"
  },
  {
    id: "refund",
    title: "Refund"
  }
  // {
  //   id: "cancel",
  //   title: "Cancelled"
  // }
];

const typeToPath = {
  tool: "inventory",
  material: "raw-material",
  others: "inventory",
  office: "inventory"
};

const ItemSummary = ({ itemSummary }) => {
  return (
    <>
      <table className="table table-condensed table-hover table-item-detail">
        <tbody>
          <tr>
            <th>Item</th>
            <th className="text-right">Ordered</th>
            <th className="text-right">Received</th>
            <th className="text-right">Cancelled</th>
            <th className="text-right">Remaining</th>
          </tr>
          {itemSummary.map(
            ({ item_id, item_type, item_name, ordered_qty, received_qty, cancelled_qty, item_unit }, i) => (
              <tr key={i}>
                <td>
                  <Link href={`/${typeToPath[item_type]}/${item_id}`}>{item_name}</Link>
                </td>
                <td className="text-right">{(ordered_qty || "").toLocaleString() + " " + item_unit}</td>
                <td className="text-right">{(received_qty || "").toLocaleString()}</td>
                <td className="text-right">{(cancelled_qty || "").toLocaleString()}</td>
                <td className="text-right">
                  {(ordered_qty - Number(received_qty) - Number(cancelled_qty) || "").toLocaleString()}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </>
  );
};

const ItemMutation = ({ itemMutation }) => {
  return (
    <table className="table table-condensed table-hover table-item-detail">
      <tbody>
        <tr>
          <th>Date</th>
          <th>Item</th>
          <th>Received</th>
          <th>Cancelled</th>
          <th>Notes</th>
        </tr>
        {itemMutation.map(({ jitm_date, item_id, item_type, item_name, type, jitm_qty, jitm_notes }, i) => (
          <tr key={i}>
            <td>{moment.unix(jitm_date).format("DD MMM YYYY")}</td>
            <td>
              <Link href={`/${typeToPath[item_type]}/${item_id}`}>{item_name}</Link>
            </td>
            <td>{type === "received" && (jitm_qty || "").toLocaleString()}</td>
            <td>{type === "cancelled" && (jitm_qty || "").toLocaleString()}</td>
            <td>{jitm_notes}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const renderBillingItems = billingItems => {
  return billingItems.map(({ item_id, item_type, jitm_amount, item_name, ordered_qty, item_unit }, i) => (
    <tr key={i}>
      <td>
        <Link href={`/${typeToPath[item_type]}/${item_id}`}>{item_name}</Link>
      </td>
      <td className="text-right">{(ordered_qty || "").toLocaleString() + " " + item_unit}</td>
      <td className="text-right">{(jitm_amount || "").toLocaleString()}</td>
      <td className="text-right">{(ordered_qty * jitm_amount).toLocaleString()}</td>
    </tr>
  ));
};

const renderBillingSubtotal = subtotal => {
  return (
    <tr>
      <th colSpan="3" className="right">
        Subtotal
      </th>
      <th className="right">{(Number(subtotal) || "").toLocaleString()}</th>
    </tr>
  );
};

const renderBillingExtraFees = (extraFees = []) => {
  if (!Array.isArray(extraFees)) extraFees = [extraFees];
  return extraFees.map(({ title, value }, i) => (
    <tr key={i}>
      <td colSpan="3" className="right">
        {title}
      </td>
      <td className="right">{Number(value).toLocaleString()}</td>
    </tr>
  ));
};

const renderBillingPayments = billing => {
  return billing.map(({ journal_notes, claim_id, journal_date, journal_amount, acc_name, cnt_name }, i) => (
    <tr key={i}>
      <td className="right">{moment.unix(journal_date).format("DD MMM YYYY")}</td>
      <td className="right" colSpan="2">
        {journal_notes}&nbsp;
        {cnt_name || acc_name}
        {claim_id ? ` (Claimed)` : ``}
      </td>
      <td className="right">{(journal_amount || "").toLocaleString()}</td>
    </tr>
  ));
};

const renderBillingTotal = total => {
  return (
    <tr>
      <th colSpan="3" className="right">
        Total
      </th>
      <th className="right">{(total || "").toLocaleString()}</th>
    </tr>
  );
};

const renderBillingPaidRemaining = (trs_amount, billing) => {
  const paidAmount = billing.reduce((paid, { journal_amount }) => (paid += journal_amount), 0);
  const remaining = trs_amount - paidAmount;
  return (
    <>
      <tr style={{ backgroundColor: "#f5f5f5" }}>
        <td colSpan="3" className="right">
          <b>Total Paid</b>
        </td>
        <td className="right">
          <b>{(paidAmount || "").toLocaleString()}</b>
        </td>
      </tr>
      {remaining > 0 && (
        <tr>
          <td className="right" colSpan="3">
            <b>Remaining</b>
          </td>
          <td className="right">
            <b>{(remaining || "").toLocaleString()}</b>
          </td>
        </tr>
      )}
    </>
  );
};

export default function PurchaseOrderView() {
  const { trs_id } = useParams();
  const [purchaseOrder, setPurchaseOrder] = useState();
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [receiveDate, setReceiveDate] = useState(new Date());

  useEffect(async () => {
    try {
      const { data } = await getPO(trs_id);
      const {
        data: { accounts }
      } = await getPaymentAccounts({ claim: true });
      setPurchaseOrder(data.purchaseOrder);
      setPaymentAccounts(accounts);
    } catch (error) {
      alert(error.data);
    }
  }, []);

  const onSubmitPaymentForm = async e => {
    e.preventDefault();
    const [acc_id, cnt_id = null] = e.target.paymentAccount.value.split("-");
    try {
      await updatePOBilling({
        type: e.target.billingType.value,
        trs_id,
        acc_id,
        cnt_id,
        journal_amount: e.target.amount.value,
        journal_date: moment(paymentDate).unix(),
        journal_notes: e.target.notes.value
      });
      const { data } = await getPO(trs_id);
      setPurchaseOrder(data.purchaseOrder);
      e.target.notes.value = "";
      e.target.amount.value = "";
    } catch (e) {
      alert(e.data);
    }
  };

  const onClickReceiveAll = async () => {
    try {
      await receiveAll(trs_id);
      const { data } = await getPO(trs_id);
      setPurchaseOrder(data.purchaseOrder);
    } catch (error) {
      alert(error.data);
    }
  };

  const onClickClosePO = async () => {
    try {
      await closePO(trs_id);
      const { data } = await getPO(trs_id);
      setPurchaseOrder(data.purchaseOrder);
    } catch (error) {
      alert(error.data);
    }
  };

  const renderPOStatus = () => {
    const po_status = purchaseOrder.po_status.value;
    const className = {
      open: "bg-green-active",
      "on-hold": "bg-red-active",
      closed: "bg-gray-active"
    };
    return po_status === "closed" ? (
      <>
        <div className="inputFile">
          <button className="btn btn-default">Upload Receipt</button>
          <input
            type="file"
            onChange={async e => {
              const { data } = await uploadReceipt(trs_id, { receipt: e.target.files[0] });
              setPurchaseOrder({ ...purchaseOrder, trs_file: data.trs_file + "?t=" + moment().unix() });
            }}
          />
        </div>
        <div className={`pull-right text-right po-status ${className[po_status]}`}>{po_status.toUpperCase()}</div>
      </>
    ) : (
      <>
        <div className="inputFile">
          <button className="btn btn-default">Upload Receipt</button>
          <input
            type="file"
            onChange={async e => {
              const { data } = await uploadReceipt(trs_id, { receipt: e.target.files[0] });
              setPurchaseOrder({ ...purchaseOrder, trs_file: data.trs_file + "?t=" + moment().unix() });
            }}
          />
        </div>
        <button className="btn btn-default" onClick={onClickReceiveAll}>
          Receive All
        </button>
        <button className="btn btn-success" onClick={onClickClosePO}>
          Close PO
        </button>
      </>
    );
  };

  const onItemSubmit = async e => {
    e.preventDefault();
    console.log("onItemSubmit");
    await updatePOItem(trs_id, {
      type: e.target.type.value,
      item_id: e.target.item_id.value,
      jitm_qty: e.target.jitm_qty.value,
      jitm_date: moment(receiveDate).unix()
    });
  };

  const renderItemSummaryForm = () => {
    return (
      <div className="box-footer">
        <form onSubmit={onItemSubmit}>
          <div style={{ display: "flex" }}>
            <ReactDatePicker
              placeholderText="date"
              selected={receiveDate}
              onChange={setReceiveDate}
              dateFormat="dd MMM yyyy"
            />
            <select className="form-control pull-right input-sm" placeholder="status" name="type">
              <option value="receive">Received</option>
              <option value="return">Return</option>
              <option value="cancel">Cancelled</option>
            </select>
            <select className="form-control pull-right input-sm" placeholder="item" name="item_id">
              <option>-- Item --</option>
              {billing_items.map(({ item_id, item_name }, i) => {
                return (
                  <option value={item_id} key={i}>
                    {item_name}
                  </option>
                );
              })}
            </select>
            <input className="form-control pull-right input-sm" placeholder="qty" name="jitm_qty" />
            <button type="submit" className="btn btn-primary btn-sm pull-right">
              Update
            </button>
          </div>
        </form>
      </div>
    );
  };

  const renderPaymentForm = () => {
    return (
      <form onSubmit={onSubmitPaymentForm}>
        <div style={{ display: "flex", width: "100%" }}>
          <ReactDatePicker
            placeholderText="date"
            selected={paymentDate}
            onChange={setPaymentDate}
            dateFormat="dd MMM yyyy"
          />
          <select className="form-control input-sm" name="billingType">
            {billingTypes.map(({ id, title }, i) => (
              <option value={id} key={i}>
                {title}
              </option>
            ))}
          </select>
          <select className="form-control input-sm" name="paymentAccount">
            {paymentAccounts.map(({ acc_id, acc_name, cnt_id = "" }, i) => (
              <option value={`${acc_id}-${cnt_id}`} key={i}>
                {acc_name}
              </option>
            ))}
          </select>
          <input className="form-control input-sm" placeholder="Amount" name="amount" required />
        </div>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px" }}>
          {/* <button className="btn btn-square" type="button">
            <i className="fa fa-camera"></i>
          </button> */}
          <input className="form-control input-sm" placeholder="Notes" style={{ width: "80%" }} name="notes" />
          <button type="submit" className="btn btn-primary btn-sm">
            Update
          </button>
        </div>
      </form>
    );
  };

  if (!purchaseOrder) return "Loading data...";
  const { item_summary, item_journal, billing_items, billing, trs_code, extra_fee, trs_file } = purchaseOrder;
  const isClosed = purchaseOrder.po_status.value === "closed";
  return (
    <div className="content-wrapper" id="po-view">
      <section className="content-header">
        <h1>
          Purchase Order <span>{trs_code}</span>
        </h1>
        {renderPOStatus()}
      </section>
      <section className="content">
        <div className="row">
          <div className="col-lg-6">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">PO Summary</h3>
                {/* {!isClosed && (
                  <div
                    className="fa fa-edit pull-right"
                    onClick={showModal}
                    style={{
                      backgroundColor: "transparent",
                      fontSize: "22px",
                      cursor: "pointer"
                    }}
                  />
                )} */}
              </div>
              <div className="box-body">
                <div className="row">
                  <label className="col-xs-2 control-label">Code</label>
                  <div className="col-xs-4">{trs_code}</div>

                  <label className="col-xs-3 control-label">Order Date</label>
                  <div className="col-xs-3">{moment.unix(purchaseOrder.trs_date).format("DD MMM YYYY")}</div>
                </div>
                <div className="row">
                  {purchaseOrder.cnt_id && (
                    <>
                      <label className="col-xs-2 control-label">Supplier</label>
                      <div className="col-xs-4">
                        <Link href={`/suppliers/${purchaseOrder.cnt_id}`}>{purchaseOrder.cnt_name}</Link>
                      </div>
                    </>
                  )}
                  {purchaseOrder.po_est_date && (
                    <>
                      <label className="col-xs-3 control-label">{purchaseOrder.po_est_date.value}</label>
                      <div className="col-xs-3">
                        {moment.unix(purchaseOrder.po_est_date.value).format("DD MMM YYYY")}
                      </div>
                    </>
                  )}
                </div>
                <div className="row">
                  {purchaseOrder.po_deposit && (
                    <>
                      <label className="col-xs-2 control-label">{purchaseOrder.po_deposit.title}</label>
                      <div className="col-xs-4">{(purchaseOrder.po_deposit.value || "").toLocaleString()}</div>
                    </>
                  )}
                  {purchaseOrder.po_due_date && (
                    <>
                      <label className="col-xs-3 control-label">Deposit Due Date</label>
                      <div className="col-xs-3">{"07 Nov 2020"}</div>
                    </>
                  )}
                </div>
                <br />
                <div className="row">
                  <label className="col-xs-2 control-label">Description</label>
                  <div className="col-xs-10">{purchaseOrder.trs_desc}</div>
                </div>
              </div>

              <div className="box-body no-padding box-scroll">
                <table className="table table-condensed table-hover">
                  <tbody>
                    <tr style={{ backgroundColor: "#f5f5f5" }}>
                      <th>Item</th>
                      <th className="text-right">Qty</th>
                      <th className="text-right">Unit Price</th>
                      <th className="text-right">Total Price</th>
                    </tr>
                    {renderBillingItems(billing_items)}
                    {extra_fee && renderBillingSubtotal(purchaseOrder.subtotal.value)}
                    {renderBillingExtraFees(extra_fee)}
                    {renderBillingTotal(purchaseOrder.trs_amount)}
                    {renderBillingPayments(billing)}
                    {renderBillingPaidRemaining(purchaseOrder.trs_amount, billing)}
                  </tbody>
                </table>
              </div>
              {!isClosed && <div className="box-footer">{renderPaymentForm(paymentAccounts)}</div>}
            </div>
            <Tabs
              tabs={["Item Summary", "History"]}
              contents={[
                <ItemSummary itemSummary={item_summary} key="0" />,
                <ItemMutation itemMutation={item_journal} key="1" />
              ]}
              footer={!isClosed && renderItemSummaryForm()}
            />
          </div>
          {trs_file && (
            <div className="col-lg-6">
              <div className="box box-primary" style={{ padding: "15px" }}>
                <img
                  alt="invoice"
                  src={`${process.env.REACT_APP_API_URL}${trs_file}`}
                  style={{ borderRadius: "15px", maxWidth: "100%", maxHeight: "300px" }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
