import React, { useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { getAccountsTotal } from "../services/account";
import ClaimForm from "../modals/claimForm";
import JournalForm from "../modals/journalForm";
import ItemForm from "../modals/itemForm";

export default function Dashboard() {
  const [showModalJournal, hideModalJournal] = useModal(() => (
    <JournalForm showModal={showModalJournal} hideModal={hideModalJournal} />
  ));
  const [showModalClaim, hideModalClaim] = useModal(() => (
    <ClaimForm showModal={showModalClaim} hideModal={hideModalClaim} />
  ));
  const [showModalItem, hideModalItem] = useModal(() => (
    <ItemForm showModal={showModalItem} hideModal={hideModalItem} />
  ));
  const [accounts, setAccounts] = useState([]);

  useEffect(async () => {
    const { data } = await getAccountsTotal();
    setAccounts(data.accounts);
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Dashboard
          <small></small>
        </h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div style={{ display: "flex", marginBottom: "15px" }}>
                  {accounts.map(({ acc_name, total }, i) => {
                    return (
                      <div key={i} style={{ paddingRight: "25px" }}>
                        {acc_name} : {total.toLocaleString()}
                      </div>
                    );
                  })}
                </div>
                <button onClick={showModalJournal}>Create New Journal</button>&nbsp;
                <button onClick={showModalClaim}>Claim Expense</button>&nbsp;
                <button onClick={showModalItem}>New Item</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
