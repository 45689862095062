import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Th from "../components/th";

export default function RawMaterial() {
  const history = useHistory();
  const [rawMaterial] = useState();
  if (!rawMaterial)
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>Coming soon..</h1>
        </section>
      </div>
    );
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Raw Materials
          <small>
            &nbsp;
            <a
              href="/raw-material/create"
              onClick={e => {
                e.stopPropagation();
                history.push("/raw-material/create");
              }}>
              Create new
            </a>
          </small>
        </h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="dataTables_wrapper form-inline dt-bootstrap">
                  <div className="row">
                    <div className="col-sm-12">
                      <table className="table table-bordered table-striped table-hover dataTable">
                        <thead>
                          <tr>
                            <Th className="sorting_asc">Code</Th>
                            <Th>Name</Th>
                            <Th>Description</Th>
                            <Th>Ordered</Th>
                            <Th>Raw</Th>
                            <Th>On Kiln</Th>
                            <Th>Ready</Th>
                            <Th>Total</Th>
                            <Th>Demand</Th>
                            <Th>To Order</Th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="odd">
                            <td>RM-201103-001</td>
                            <td>Wooden Tray</td>
                            <td className="sorting_1">Suar wood</td>
                            <td>10</td>
                            <td>7</td>
                            <td>1</td>
                            <td>1</td>
                            <td>19</td>
                            <td>20</td>
                            <td style={{ backgroundColor: "#ffbbbb" }}>1</td>
                          </tr>
                          <tr className="even">
                            <td>RM-201103-002</td>
                            <td>Nina Vas</td>
                            <td className="sorting_1">Suar wood</td>

                            <td>13</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>16</td>
                            <td>1</td>
                            <td></td>
                          </tr>
                          <tr className="odd">
                            <td>RM-201103-003</td>
                            <td>Palm Wood</td>
                            <td className="sorting_1">Wood from Palm tree</td>

                            <td>4</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>7</td>
                            <td>10</td>
                            <td style={{ backgroundColor: "#ffbbbb" }}>3</td>
                          </tr>
                          <tr className="even">
                            <td>RM-201103-004</td>
                            <td>Barnacle</td>
                            <td className="sorting_1">Arthropod Cirripedia</td>
                            <td>2</td>
                            <td>1</td>
                            <td></td>
                            <td>1</td>
                            <td>4</td>
                            <td>1</td>
                            <td></td>
                          </tr>
                          <tr className="odd">
                            <td>RM-201103-004</td>
                            <td>Terracota (S)</td>
                            <td>Earthenware ceramic</td>
                            <td>4</td>
                            <td>1</td>
                            <td></td>
                            <td>1</td>
                            <td>6</td>
                            <td>2</td>
                            <td></td>
                          </tr>
                          <tr className="even">
                            <td>RM-201103-004</td>
                            <td>Terracota (M)</td>
                            <td>Earthenware ceramic</td>
                            <td>6</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                            <td>11</td>
                            <td>15</td>
                            <td style={{ backgroundColor: "#ffbbbb" }}>4</td>
                          </tr>
                          <tr className="odd">
                            <td>RM-201103-004</td>
                            <td>Terracota (L)</td>
                            <td>Earthenware ceramic</td>
                            <td>8</td>
                            <td></td>
                            <td>1</td>
                            <td>1</td>
                            <td>10</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="even">
                            <td>RM-201103-004</td>
                            <td>Teak Wood</td>
                            <td>Wooden teak</td>
                            <td>7</td>
                            <td></td>
                            <td>1</td>
                            <td>2</td>
                            <td>10</td>
                            <td>12</td>
                            <td style={{ backgroundColor: "#ffbbbb" }}>2</td>
                          </tr>
                          <tr className="odd">
                            <td>RM-201103-004</td>
                            <td>Nails</td>
                            <td>Used with hammer</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>2</td>
                            <td>5</td>
                            <td>2</td>
                            <td></td>
                          </tr>
                          <tr className="even">
                            <td>RM-201103-004</td>
                            <td>Wood Glue</td>
                            <td>Sticking wood together</td>
                            <td>1</td>
                            <td></td>
                            <td>2</td>
                            <td>2</td>
                            <td>5</td>
                            <td>1</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
