import React, { useState } from "react";

export default function Tabs({ tabs, contents, footer }) {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="nav-tabs-custom">
      <ul className="nav nav-tabs">
        {tabs.map((tab, i) => (
          <li className={activeIndex === i ? `active` : ``} key={i} onClick={() => setActiveIndex(i)}>
            <a href="/" className="box-title" onClick={e => e.preventDefault()}>
              {tab}
            </a>
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {contents.map((content, i) => {
          return (
            <div className={activeIndex === i ? `tab-pane active` : `tab-pane`} key={i}>
              {content}
            </div>
          );
        })}
        {footer}
      </div>
    </div>
  );
}
