import httpService from "./httpService";

export function getItemsAll() {
  return httpService.get(`${process.env.REACT_APP_API_URL}/items/all`);
}

export function getItemSuggestion(data) {
  let uri = `${process.env.REACT_APP_API_URL}/item/suggestion?`;
  if (data.new_item_name) uri += `new_item_name=${data.new_item_name}`;
  return httpService.get(uri);
}

export function createItem(data) {
  return httpService.post(`${process.env.REACT_APP_API_URL}/item`, data);
}
