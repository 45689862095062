import httpService from "./httpService";

export function getPaymentAccounts(data) {
  let uri = `${process.env.REACT_APP_API_URL}/accounts/payment?`;
  if (data.claim) uri += `claim=true&`;
  if (data.equity) uri += `equity=true`;
  return httpService.get(uri);
}

export function getExpenseAccounts() {
  return httpService.get(`${process.env.REACT_APP_API_URL}/accounts/expense`);
}

export function getInflowAccount() {
  return httpService.get(`${process.env.REACT_APP_API_URL}/accounts/inflow`);
}

export function getOutflowAccount() {
  return httpService.get(`${process.env.REACT_APP_API_URL}/accounts/outflow`);
}

export function getAccountsTotal() {
  return httpService.get(`${process.env.REACT_APP_API_URL}/accounts/total`);
}
