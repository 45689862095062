import React, { useEffect, useState } from "react";
import { getSuppliers } from "../services/supplier";
export default function Supplier() {
  const [suppliers, setSuppliers] = useState();
  useEffect(async () => {
    try {
      const { data } = await getSuppliers();
      setSuppliers(data.suppliers);
    } catch (error) {
      alert(error.data);
    }
  }, []);
  if (!suppliers) return "Loading data...";
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Suppliers
          <small>{/* Can add description here */}</small>
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div id="example1_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                  <div className="row">
                    <div className="col-sm-12">
                      <table
                        id="example1"
                        className="table table-bordered table-striped table-hover dataTable"
                        role="grid"
                        aria-describedby="example1_info">
                        <thead>
                          <tr role="row">
                            <th>No</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th>Remaining Order</th>
                            <th>Remaining Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {suppliers.map(
                            ({ cnt_name, remaining_qty, remaining_payment, phone, email, address, cnt_status }, i) => {
                              const className = i % 2 ? "odd" : "even";
                              return (
                                <tr key={i} className={className}>
                                  <td>{i + 1}</td>
                                  <td>{cnt_name}</td>
                                  <td>{phone}</td>
                                  <td>{email}</td>
                                  <td>{address}</td>
                                  <td>{cnt_status}</td>
                                  <td>{(remaining_qty || "").toLocaleString()}</td>
                                  <td>{(remaining_payment || "").toLocaleString()}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
