import React from "react";
export default function Modal({ showModal, hideModal, title, children }) {
  return (
    <div className={`modal fade ${showModal ? `in block` : `in`}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={hideModal}>
              <span>&times;</span>
            </button>
            <h4 className="modal-title">{title}</h4>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
