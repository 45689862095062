import httpService from "./httpService";

export function createJournals(data) {
  return httpService.post(`${process.env.REACT_APP_API_URL}/journals`, data);
}
export function getExpenses() {
  return httpService.get(`${process.env.REACT_APP_API_URL}/expenses`);
}
export function getIncomes() {
  return httpService.get(`${process.env.REACT_APP_API_URL}/incomes`);
}
export function getTransactions() {
  return httpService.get(`${process.env.REACT_APP_API_URL}/transactions`);
}
export function getUnclaimedJournals(id) {
  return httpService.get(`${process.env.REACT_APP_API_URL}/unclaimed/${id}`);
}
export function claimJournals(data) {
  return httpService.post(`${process.env.REACT_APP_API_URL}/claim`, data);
}
export function uploadReceipt(id, data) {
  const formData = new FormData();
  if (data.receipt) formData.append("receipt", data.receipt);
  return httpService.post(`${process.env.REACT_APP_API_URL}/transaction/file/${id}`, formData);
}
