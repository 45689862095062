import httpService from "./httpService";

export function getPOs() {
  return httpService.get(`${process.env.REACT_APP_API_URL}/po`);
}

export function getPO(id) {
  return httpService.get(`${process.env.REACT_APP_API_URL}/po/${id}`);
}

export function createPO(data) {
  return httpService.post(`${process.env.REACT_APP_API_URL}/po`, data);
}

export function updatePOBilling(data) {
  return httpService.post(`${process.env.REACT_APP_API_URL}/po/billing`, data);
}

export function closePO(id) {
  return httpService.put(`${process.env.REACT_APP_API_URL}/po/close/${id}`);
}

export function updatePOItem(id, data) {
  return httpService.post(`${process.env.REACT_APP_API_URL}/po/item/${id}`, data);
}

export function receiveAll(id) {
  return httpService.post(`${process.env.REACT_APP_API_URL}/po/item/${id}/receive/all`);
}
