import React, { useEffect, useState } from "react";
import { getEmployees } from "../services/employee";
import moment from "moment";
import { useHistory } from "react-router-dom";
export default function Employee() {
  const [employees, setEmployees] = useState();
  const history = useHistory();
  useEffect(async () => {
    try {
      const { data } = await getEmployees();
      setEmployees(data.employees);
    } catch (error) {
      alert(error.data);
    }
  }, []);
  if (!employees) return "Loading data";
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Employees
          <small>{/* Can add description here */}</small>
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div id="example1_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                  <div className="row">
                    <div className="col-sm-12">
                      <table
                        id="example1"
                        className="table table-bordered table-striped table-hover dataTable"
                        role="grid"
                        aria-describedby="example1_info">
                        <thead>
                          <tr role="row">
                            <th>No</th>
                            <th>Name</th>
                            <th>Job Title</th>
                            <th>Starting Date</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {employees.map(
                            ({ cnt_name, phone, address, email, created_at, job_title, cnt_status, cnt_id }, i) => {
                              return (
                                <tr key={i} onClick={() => history.push(`/employee/${cnt_id}`)}>
                                  <td>{i + 1}</td>
                                  <td>{cnt_name}</td>
                                  <td>{job_title}</td>
                                  <td>{moment.unix(created_at).format("DD MMM YYYY")}</td>
                                  <td>{phone}</td>
                                  <td>{email}</td>
                                  <td>{address}</td>
                                  <td>{cnt_status}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
