import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useUIContext } from "../context/UIContext";
const MENU = [
  {
    path: "/raw-materials",
    icon: "fa fa-cubes",
    title: "Raw Material"
  },
  {
    path: "/purchase-orders",
    icon: "fa fa-file-text-o",
    title: "Purchase Order"
  },
  {
    path: "/working-lists",
    icon: "fa fa-list",
    title: "Production List"
  },
  {
    path: "/customer-orders",
    icon: "fa fa-files-o",
    title: "Customer Order"
  },
  {
    path: "/shipping",
    icon: "fa fa-truck",
    title: "Pengiriman/Pengambilan"
  },
  {
    path: "/transaction",
    icon: "fa fa-dollar",
    title: "Transactions"
  },
  {
    path: "/products",
    icon: "fa fa-cube",
    title: "Products"
  },
  {
    path: "/inventories",
    icon: "fa fa-wrench",
    title: "Inventory"
  },
  {
    path: "/employees",
    icon: "fa fa-users",
    title: "Employees"
  },
  {
    path: "/suppliers",
    icon: "fa fa-user",
    title: "Suppliers"
  },
  {
    path: "/customers",
    icon: "fa fa-user-plus",
    title: "Customers"
  }
];
export default function SideBar() {
  const { setSideBarCollapse } = useUIContext();
  const history = useHistory();
  const location = useLocation();
  return (
    <aside className="main-sidebar">
      <section className="sidebar">
        <br />
        <br />
        <ul className="sidebar-menu" data-widget="tree">
          {MENU.map(({ path, title, icon }, i) => (
            <li key={i} className={`${location.pathname === path ? `active` : ``}`}>
              <a
                href={path}
                onClick={e => {
                  e.preventDefault();
                  history.push(path);
                  setSideBarCollapse(true);
                }}>
                <i className={icon}></i>
                <span>{title}</span>
              </a>
            </li>
          ))}
        </ul>
      </section>
    </aside>
  );
}
