import React, { useState } from "react";
export default function Customer() {
  const [customers] = useState();
  if (!customers)
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>Coming soon..</h1>
        </section>
      </div>
    );
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Customers
          <small>{/* Can add description here */}</small>
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div id="example1_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                  <div className="row">
                    <div className="col-sm-12">
                      <table
                        id="example1"
                        className="table table-bordered table-striped table-hover dataTable"
                        role="grid"
                        aria-describedby="example1_info">
                        <thead>
                          <tr role="row">
                            <th className="sorting">Name</th>
                            <th className="sorting">Number of Transaction</th>
                            <th className="sorting">Transaction Amount</th>
                            <th className="sorting">On Going</th>
                            <th className="sorting">Remaining Payment</th>
                            <th className="sorting">Phone Number</th>
                            <th className="sorting">Email</th>
                            <th className="sorting">Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" className="odd">
                            <td>Vanya</td>
                            <td>9</td>
                            <td>9,000,000</td>
                            <td>1</td>
                            <td>2,000,000</td>
                            <td>08745628457</td>
                            <td>vanya@gmail.com</td>
                            <td>Sunset Road</td>
                          </tr>
                          <tr role="row" className="even">
                            <td>Yola</td>
                            <td>3</td>
                            <td>6,000,000</td>
                            <td></td>
                            <td></td>
                            <td>08745628400</td>
                            <td>yolanda@gmail.com</td>
                            <td>Jakarta</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>Morgan</td>
                            <td>1</td>
                            <td>160,000,000</td>
                            <td>2</td>
                            <td>50,000,000</td>
                            <td>087456282333</td>
                            <td>Morgan@gmail.com</td>
                            <td>Gianyar</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>Memet</td>
                            <td>5</td>
                            <td>240,000,000</td>
                            <td></td>
                            <td></td>
                            <td>087456282333</td>
                            <td>memet@gmail.com</td>
                            <td>Badung</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>Vanya</td>
                            <td>9</td>
                            <td>9,000,000</td>
                            <td>1</td>
                            <td>2,000,000</td>
                            <td>08745628457</td>
                            <td>vanya@gmail.com</td>
                            <td>Sunset Road</td>
                          </tr>
                          <tr role="row" className="even">
                            <td>Yola</td>
                            <td>3</td>
                            <td>6,000,000</td>
                            <td></td>
                            <td></td>
                            <td>08745628400</td>
                            <td>yolanda@gmail.com</td>
                            <td>Jakarta</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>Morgan</td>
                            <td>1</td>
                            <td>160,000,000</td>
                            <td>2</td>
                            <td>50,000,000</td>
                            <td>087456282333</td>
                            <td>Morgan@gmail.com</td>
                            <td>Gianyar</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>Memet</td>
                            <td>5</td>
                            <td>240,000,000</td>
                            <td></td>
                            <td></td>
                            <td>087456282333</td>
                            <td>memet@gmail.com</td>
                            <td>Badung</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>Morgan</td>
                            <td>1</td>
                            <td>160,000,000</td>
                            <td>2</td>
                            <td>50,000,000</td>
                            <td>087456282333</td>
                            <td>Morgan@gmail.com</td>
                            <td>Gianyar</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>Memet</td>
                            <td>5</td>
                            <td>240,000,000</td>
                            <td></td>
                            <td></td>
                            <td>087456282333</td>
                            <td>memet@gmail.com</td>
                            <td>Badung</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
