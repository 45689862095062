import React, { useState } from "react";
import Modal from "../components/modal";
import { createItem, getItemSuggestion } from "../services/item";
import { useModal } from "react-modal-hook";

const itemTypes = ["inventory", "material", "product", "others"];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function ItemForm({ showModal, hideModal }) {
  const [similarItems, setSimilarItems] = useState([]);
  const [item_name, setItem_name] = useState("");
  const [item_type, setItem_type] = useState("");
  const [item_unit, setItem_unit] = useState("");
  const [showConfirm, hideConfirm] = useModal(
    () => (
      <Modal showModal={showConfirm} hideModal={hideConfirm} title="Similar Item Found">
        <div className="modal-body" id="item-modal">
          <div className="box-body no-padding box-scroll">{renderSimilarItems()}</div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-secondary" onClick={submitNewItem}>
            Confirm
          </button>
          <button className="btn btn-primary" onClick={hideConfirm}>
            Cancel
          </button>
        </div>
      </Modal>
    ),
    [similarItems]
  );
  const onSubmit = async e => {
    e.preventDefault();
    try {
      const {
        data: { items }
      } = await getItemSuggestion({ new_item_name: e.target.item_name.value });
      if (items.length) {
        setSimilarItems(items);
        showConfirm();
        return;
      }
      submitNewItem();
    } catch (error) {
      alert(error.data || error.toString());
    }
  };

  const submitNewItem = async () => {
    try {
      await createItem({ item_type, item_name, item_unit });
      hideConfirm();
      hideModal();
      alert("Item sucessfully created");
    } catch (error) {
      alert(error.data || error.toString());
    }
  };

  const renderSimilarItems = () => {
    return (
      similarItems.length > 0 && (
        <>
          <table className="table table-bordered table-striped table-hover dataTable">
            <tbody>
              <tr>
                <th>Item Code</th>
                <th>Item Name</th>
              </tr>
              {similarItems.map(({ item_code, item_name }, i) => (
                <tr key={i}>
                  <td>{item_code}</td>
                  <td>{item_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <h4 className="similarItemTitle">Are you sure you want to add new item &quot;{item_name}&quot; ?</h4>
        </>
      )
    );
  };

  return (
    <Modal showModal={showModal} hideModal={hideModal} title="New Item">
      <form onSubmit={onSubmit} onKeyDown={e => e.code == "Enter" && e.preventDefault()}>
        <div className="modal-body" id="item-modal">
          <div className="box-body no-padding box-scroll">
            <div className="flex">
              <select
                required
                className="form-control input-xs select-type"
                name="item_type"
                value={item_type}
                onChange={e => setItem_type(e.target.value)}>
                <option>-- Select Type --</option>
                {itemTypes.map((item_type, i) => {
                  return (
                    <option key={i} value={item_type}>
                      {capitalizeFirstLetter(item_type)}
                    </option>
                  );
                })}
              </select>
              <input
                className="form-control input-xs input-name"
                name="item_name"
                required
                value={item_name}
                onChange={e => setItem_name(e.target.value)}
                placeholder="Item name"
              />
              <input
                className="form-control input-xs input-name"
                name="item_unit"
                required
                value={item_unit}
                onChange={e => setItem_unit(e.target.value)}
                placeholder="Item unit"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-secondary" onClick={hideModal}>
            Discard
          </button>
          <button className="btn btn-primary" type="submit">
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
}
