import React, { useEffect, useState } from "react";
import { getTransactions } from "../services/transaction";
import moment from "moment";
import Link from "../components/link";

export default function Transaction() {
  const [transactions, setTransactions] = useState([]);

  useEffect(async () => {
    const { data } = await getTransactions();
    setTransactions(data.transactions);
  }, []);
  if (!transactions.length) return "No data to display";

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Transactions
          <small></small>
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div id="example1_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                  <div className="row">
                    <div className="col-sm-12">
                      <table className="table table-bordered table-striped table-hover dataTable">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Date</th>
                            <th className="text-right">Amount</th>
                            <th colSpan="2">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions.map(({ trs_date, trs_desc, trs_id, trs_type, trs_code, trs_amount }, i) => {
                            const className = i % 2 ? "odd" : "even";
                            return (
                              <tr key={i} className={className}>
                                <td>{i + 1}</td>
                                <td>{moment.unix(trs_date).format("DD MMM YYYY")}</td>
                                <td className="text-right">{(trs_amount || "").toLocaleString()}</td>
                                <td>{trs_desc}</td>
                                <td>
                                  <Link href={`/${trs_type}/${trs_id}`}>{trs_code}</Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
