import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getEmployee } from "../services/employee";
import moment from "moment";

export default function EmployeeView() {
  const [employee, setEmployee] = useState();
  const { cnt_id } = useParams();
  const history = useHistory();
  useEffect(async () => {
    try {
      const { data } = await getEmployee(cnt_id);
      setEmployee(data.employee);
    } catch (error) {
      alert(error.data);
    }
  }, []);
  const renderContactDetail = () => {
    return (
      <table className="table dataTable" style={{ width: "50%" }}>
        <tbody>
          {employee.contact_details.map(({ title, value }, i) => (
            <tr key={i}>
              <th>{title}</th>
              <td>{value}</td>
            </tr>
          ))}
          {(employee.liability && employee.liability.length) || (employee.equity && employee.equity.length) ? (
            <tr>
              <th>Total uang di perusahaan</th>
              <td>
                {[...employee.equity, ...employee.liability]
                  .reduce((total, { journal_amount }) => (total += journal_amount), 0)
                  .toLocaleString()}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    );
  };
  const renderClaimedLiability = () => {
    return (
      employee.claimed_liability &&
      employee.claimed_liability.length > 0 && (
        <>
          <br />
          <h4>&nbsp;&nbsp;Claimed Liability</h4>
          <table className="table dataTable table-striped table-hover tableExpense">
            <thead>
              <tr>
                <th>No.</th>
                <th>Date</th>
                <th className="right">Amount</th>
                <th>Transaction</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {employee.claimed_liability.map(
                ({ trs_id, trs_type, trs_code, trs_desc, journal_date, journal_amount }, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{moment.unix(journal_date).format("DD MMMM YYYY")}</td>
                    <td className="right">{(journal_amount || "").toLocaleString()}</td>
                    <td>
                      {trs_type === "purchase-order" && (
                        <a
                          href={`/purchase-order/${trs_id}`}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            history.push(`/purchase-order/${trs_id}`);
                          }}>
                          {trs_code}
                        </a>
                      )}
                    </td>
                    <td>{[trs_desc].join(", ")}</td>
                  </tr>
                )
              )}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="2" className="right">
                  Total
                </th>
                <th className="right">
                  {employee.claimed_liability
                    .reduce((total, { journal_amount }) => (total += journal_amount), 0)
                    .toLocaleString()}
                </th>
                <th colSpan="2" />
              </tr>
            </tfoot>
          </table>
        </>
      )
    );
  };

  const renderUnclaimedLiability = () => {
    return (
      employee.liability &&
      employee.liability.length > 0 && (
        <>
          <br />
          <h4>&nbsp;&nbsp;Unclaimed Liability</h4>
          <table className="table dataTable table-striped table-hover tableExpense">
            <thead>
              <tr>
                <th>No.</th>
                <th>Date</th>
                <th className="right">Amount</th>
                <th>Transaction</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {employee.liability.map(({ trs_id, trs_type, trs_code, trs_desc, journal_date, journal_amount }, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{moment.unix(journal_date).format("DD MMMM YYYY")}</td>
                  <td className="right">{(journal_amount || "").toLocaleString()}</td>
                  <td>
                    {trs_type === "purchase-order" && (
                      <a
                        href={`/purchase-order/${trs_id}`}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push(`/purchase-order/${trs_id}`);
                        }}>
                        {trs_code}
                      </a>
                    )}
                  </td>
                  <td>{[trs_desc].join(", ")}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="2" className="right">
                  Total
                </th>
                <th className="right">
                  {employee.liability
                    .reduce((total, { journal_amount }) => (total += journal_amount), 0)
                    .toLocaleString()}
                </th>
                <th colSpan="2" />
              </tr>
            </tfoot>
          </table>
        </>
      )
    );
  };

  const renderEquity = () => {
    return (
      employee.equity &&
      employee.equity.length > 0 && (
        <>
          <br />
          <h4>&nbsp;&nbsp;Equity</h4>
          <table className="table dataTable table-striped table-hover tableExpense">
            <thead>
              <tr>
                <th>No.</th>
                <th>Date</th>
                <th className="right">Amount</th>
                <th>Transaction</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {employee.equity.map(({ trs_id, trs_type, trs_code, trs_desc, journal_date, journal_amount }, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{moment.unix(journal_date).format("DD MMMM YYYY")}</td>
                  <td className="right">{(journal_amount || "").toLocaleString()}</td>
                  <td>
                    {trs_type === "purchase-order" && (
                      <a
                        href={`/purchase-order/${trs_id}`}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push(`/purchase-order/${trs_id}`);
                        }}>
                        {trs_code}
                      </a>
                    )}
                  </td>
                  <td>{[trs_desc].join(", ")}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="2" className="right">
                  Total
                </th>
                <th className="right">
                  {employee.equity.reduce((total, { journal_amount }) => (total += journal_amount), 0).toLocaleString()}
                </th>
                <th colSpan="2" />
              </tr>
            </tfoot>
          </table>
        </>
      )
    );
  };

  const renderExpense = () => {
    return (
      employee.expense &&
      employee.expense.length > 0 && (
        <>
          <br />
          <h4>&nbsp;&nbsp;Expense</h4>
          <table className="table dataTable table-striped table-hover tableExpense">
            <thead>
              <tr>
                <th>No.</th>
                <th>Date</th>
                <th className="right">Amount</th>
                <th>Transaction</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {employee.expense.map(({ trs_id, trs_type, trs_code, trs_desc, journal_date, journal_amount }, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{moment.unix(journal_date).format("DD MMMM YYYY")}</td>
                  <td className="right">{(journal_amount || "").toLocaleString()}</td>
                  <td>
                    {trs_type === "purchase-order" && (
                      <a
                        href={`/purchase-order/${trs_id}`}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push(`/purchase-order/${trs_id}`);
                        }}>
                        {trs_code}
                      </a>
                    )}
                  </td>
                  <td>{[trs_desc].join(", ")}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="2" className="right">
                  Total
                </th>
                <th className="right">
                  {employee.expense
                    .reduce((total, { journal_amount }) => (total += journal_amount), 0)
                    .toLocaleString()}
                </th>
                <th colSpan="2" />
              </tr>
            </tfoot>
          </table>
        </>
      )
    );
  };

  if (!employee) return "Loading data...";

  return (
    <div id="employeeView" className="content-wrapper">
      <section className="content-header">
        <h1>
          {employee.cnt_name}
          <small>{employee.cnt_code}</small>
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              {renderContactDetail()}
              {renderEquity()}
              {renderClaimedLiability()}
              {renderUnclaimedLiability()}
              {renderExpense()}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
