import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import SideBar from "./components/sidebar";
import ToolBar from "./components/toolbar";
import { useUIContext } from "./context/UIContext";
import PurchaseOrder from "./pages/purchaseOrder";
import PurchaseOrderView from "./pages/purchaseOrderView";
import RawMaterial from "./pages/rawMaterial";
import WorkingList from "./pages/workingList";
import CustomerOrder from "./pages/customerOrder";
import Product from "./pages/product";
import Inventory from "./pages/inventory";
import Employee from "./pages/employee";
import Supplier from "./pages/supplier";
import Customer from "./pages/customer";
import Shipping from "./pages/shipping";
import Invoice from "./components/invoice";
import { ModalProvider } from "react-modal-hook";
import "react-datepicker/dist/react-datepicker.css";
import Expense from "./pages/expense";
import InventoryView from "./pages/inventoryView";
import EmployeeView from "./pages/employeeView";
import Income from "./pages/income";
import Dashboard from "./pages/dashboard";
import Transaction from "./pages/transaction";

function App() {
  const { sideBarCollapse } = useUIContext();
  return (
    <div className={`skin-blue sidebar-mini ${sideBarCollapse ? ` sidebar-collapse` : `sidebar-open`}`}>
      <div className="wrapper">
        <Router>
          <ModalProvider>
            <ToolBar />
            <SideBar />
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/raw-materials" component={RawMaterial} />
              <Route exact path="/raw-material/create" component={RawMaterial} />
              <Route exact path="/purchase-orders" component={PurchaseOrder} />
              <Route exact path="/purchase-order/:trs_id" component={PurchaseOrderView} />
              <Route exact path="/working-lists" component={WorkingList} />
              <Route exact path="/customer-orders" component={CustomerOrder} />
              <Route exact path="/products" component={Product} />
              <Route exact path="/inventories" component={Inventory} />
              <Route exact path="/employees" component={Employee} />
              <Route exact path="/suppliers" component={Supplier} />
              <Route exact path="/customers" component={Customer} />
              <Route exact path="/shipping" component={Shipping} />
              <Route exact path="/invoice" component={Invoice} />
              <Route exact path="/outflow" component={Expense} />
              <Route exact path="/inflow" component={Income} />
              <Route exact path="/transaction" component={Transaction} />
              <Route exact path="/inventory/:item_id" component={InventoryView} />
              <Route exact path="/employee/:cnt_id" component={EmployeeView} />
            </Switch>
          </ModalProvider>
        </Router>
      </div>
    </div>
  );
}

export default App;
