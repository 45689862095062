import React, { useEffect, useState } from "react";
import Modal from "../components/modal";
import { getEmployeesAll } from "../services/employee";
import { getInflowAccount, getOutflowAccount } from "../services/account";
import ReactDatePicker from "react-datepicker";
import { createJournals } from "../services/transaction";
import moment from "moment";

export default function JournalForm({ showModal, hideModal }) {
  const [accountsExpense, setAccountsExpense] = useState();
  const [accountsPayment, setAccountsPayment] = useState();
  const [employees, setEmployees] = useState();
  const [journalFrom, setJournalFrom] = useState([{}, {}]);
  const [journalTo, setJournalTo] = useState([{}, {}]);
  const [trs_date, setTrs_date] = useState(new Date());

  useEffect(async () => {
    const {
      data: { accounts: expense }
    } = await getOutflowAccount();
    const {
      data: { accounts: payment }
    } = await getInflowAccount();
    const {
      data: { employees }
    } = await getEmployeesAll();
    setAccountsExpense(expense);
    setAccountsPayment(payment);
    setEmployees(employees);
  }, []);

  const onBlurInput = (journal, setJournal, i) => {
    if (i !== undefined && i == journal.length - 2) {
      setJournal([...journal, {}]);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    const totalFrom = getTotal(journalFrom);
    const totalTo = getTotal(journalTo);
    if (totalFrom !== totalTo) return alert("Total amount must match");
    if (journalFrom.find(({ acc_id, journal_amount }) => journal_amount && !acc_id))
      return alert("Select an account or remove amount");
    if (journalTo.find(({ acc_id, journal_amount }) => journal_amount && !acc_id))
      return alert("Select an account or remove amount");
    try {
      await createJournals({
        transaction: {
          trs_date: moment(trs_date).unix(),
          trs_desc: e.target.trs_desc.value,
          trs_amount: totalFrom
        },
        journals: [
          ...journalFrom
            .filter(({ acc_id, journal_amount }) => acc_id && journal_amount)
            .map(({ acc_id, cnt_id, journal_amount, journal_notes }) => {
              return {
                acc_id,
                cnt_id,
                journal_notes,
                journal_amount: acc_id == 28 ? journal_amount : -journal_amount
              };
            }),
          ...journalTo
            .filter(({ acc_id, journal_amount }) => acc_id && journal_amount)
            .map(({ acc_id, cnt_id, journal_amount, journal_notes }) => {
              return {
                acc_id,
                cnt_id,
                journal_notes,
                journal_amount: acc_id == 28 ? -journal_amount : journal_amount
              };
            })
        ]
      });
      hideModal();
      alert("Journal created");
    } catch (error) {
      alert(error.data);
    }
  };

  const getTotal = journal => {
    return journal.reduce((total, { journal_amount = 0 }) => {
      total += Number(journal_amount);
      return total;
    }, 0);
  };
  if (!accountsExpense || !accountsPayment || !employees) return "Loading data...";
  return (
    <Modal showModal={showModal} hideModal={hideModal} title="New Journal">
      <form onSubmit={onSubmit} onKeyDown={e => e.code == "Enter" && e.preventDefault()}>
        <div className="modal-body" id="journal-modal">
          <div className="box-body no-padding box-scroll">
            <div style={{ display: "flex" }}>
              <ReactDatePicker
                className="form-control input-sm"
                popperPlacement="bottom-start"
                dateFormat="dd MMM yyyy"
                selected={trs_date}
                onChange={setTrs_date}
              />
              <input name="trs_desc" placeholder="Enter description" className="form-control input-sm" />
            </div>
            <br />
            <table className="table table-condensed table-item-detail">
              <tbody>
                <tr style={{ backgroundColor: "#f5f5f5" }}>
                  <th colSpan="2">From</th>
                  <th>Notes</th>
                  <th className="right">Amount</th>
                </tr>
                {journalFrom.map(({ acc_id, journal_amount, journal_notes, cnt_id }, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <select
                          className="form-control input-xs"
                          value={acc_id}
                          onChange={e => {
                            journalFrom[i].acc_id = e.target.value;
                            setJournalFrom([...journalFrom]);
                          }}>
                          <option value="">-- Select Account --</option>
                          {accountsPayment.map(({ acc_id, acc_name }, j) => (
                            <option value={acc_id} key={j}>
                              {acc_name}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td>
                        {acc_id == 28 && (
                          <select
                            className="form-control input-xs"
                            value={cnt_id || ""}
                            onChange={e => {
                              journalFrom[i].cnt_id = e.target.value;
                              setJournalFrom([...journalFrom]);
                            }}>
                            <option>-- Select --</option>
                            {employees.map(({ cnt_name, cnt_id }, i) => (
                              <option value={cnt_id} key={i}>
                                {cnt_name}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                      <td>
                        <input
                          className="form-control input-xs"
                          value={journal_notes || ""}
                          onChange={e => {
                            journalFrom[i].journal_notes = e.target.value;
                            setJournalFrom([...journalFrom]);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control input-xs"
                          value={journal_amount || ""}
                          onBlur={() => onBlurInput(journalFrom, setJournalFrom, i)}
                          onChange={e => {
                            journalFrom[i].journal_amount = e.target.value;
                            setJournalFrom([...journalFrom]);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th colSpan="2" />
                  <th className="right">Total</th>
                  <th className="right">{(getTotal(journalFrom) || "").toLocaleString()}</th>
                </tr>
                <tr>
                  <th />
                </tr>
                <tr style={{ backgroundColor: "#f5f5f5" }}>
                  <th colSpan="2">To</th>
                  <th>Notes</th>
                  <th className="right">Amount</th>
                </tr>
                {journalTo.map(({ acc_id, journal_amount, journal_notes, cnt_id }, i) => (
                  <tr key={i}>
                    <td>
                      <select
                        className="form-control input-xs"
                        value={acc_id}
                        onChange={e => {
                          const journals = [...journalTo];
                          journals[i].acc_id = e.target.value;
                          setJournalTo(journals);
                        }}>
                        <option value="">-- Select Account --</option>
                        {accountsExpense.map(({ acc_id, acc_name }, j) => (
                          <option value={acc_id} key={j}>
                            {acc_name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      {acc_id == 11 && (
                        <select
                          className="form-control input-xs"
                          value={cnt_id || ""}
                          onChange={e => {
                            journalTo[i].cnt_id = e.target.value;
                            setJournalTo([...journalTo]);
                          }}>
                          <option>-- Select --</option>
                          {employees.map(({ cnt_name, cnt_id }, i) => (
                            <option value={cnt_id} key={i}>
                              {cnt_name}
                            </option>
                          ))}
                        </select>
                      )}
                    </td>
                    <td>
                      <input
                        className="form-control input-xs"
                        value={journal_notes || ""}
                        onChange={e => {
                          journalTo[i].journal_notes = e.target.value;
                          setJournalTo([...journalTo]);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        className="form-control input-xs"
                        value={journal_amount || ""}
                        onBlur={() => onBlurInput(journalTo, setJournalTo, i)}
                        onChange={e => {
                          journalTo[i].journal_amount = e.target.value;
                          setJournalTo([...journalTo]);
                        }}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <th colSpan="2" />
                  <th className="right">Total</th>
                  <th className="right">{(getTotal(journalTo) || "").toLocaleString()}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-secondary" onClick={hideModal}>
            Discard
          </button>
          <button className="btn btn-primary" type="submit">
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
}
