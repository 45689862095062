import React, { useEffect, useState } from "react";
import Modal from "../components/modal";
import { getEmployeesAll } from "../services/employee";
import { getPaymentAccounts } from "../services/account";
import ReactDatePicker from "react-datepicker";
import { getUnclaimedJournals } from "../services/transaction";
import { claimJournals } from "../services/transaction";
import moment from "moment";
import { Link } from "react-router-dom";

export default function ClaimForm({ showModal, hideModal }) {
  const [accountsPayment, setAccountsPayment] = useState();
  const [employees, setEmployees] = useState();
  const [unclaimedJournals, setUnclaimedJournals] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [trs_date, setTrs_date] = useState(new Date());
  const [payments, setPayments] = useState([{}, {}]);

  useEffect(async () => {
    const {
      data: { accounts: payment }
    } = await getPaymentAccounts({ equity: true });
    setAccountsPayment(payment);

    const {
      data: { employees }
    } = await getEmployeesAll();
    setEmployees(employees);
  }, []);

  useEffect(async () => {
    if (selectedEmployee) {
      const { data } = await getUnclaimedJournals(selectedEmployee);
      setUnclaimedJournals(data.journals);
    }
  }, [selectedEmployee]);

  const onBlurInput = i => {
    if (i !== undefined && i == payments.length - 2) {
      setPayments([...payments, {}]);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    const incompleteJournals = payments.find(({ acc_id, journal_amount }) => !acc_id && journal_amount);
    if (incompleteJournals) return alert("Please select an account");
    const claimedJournals = unclaimedJournals.filter(({ checked }) => checked);
    const paymentJournals = payments.filter(({ acc_id, journal_amount }) => acc_id && journal_amount);
    const totalClaimed = getTotal(claimedJournals);
    const totalPayment = getTotal(paymentJournals);
    if (totalClaimed !== totalPayment) return alert("Total payment and claim must match");
    try {
      await claimJournals({
        cnt_id: selectedEmployee,
        transaction: {
          trs_date: moment(trs_date).unix(),
          trs_desc: e.target.trs_desc.value
        },
        journal_ids: claimedJournals.map(({ journal_id }) => journal_id),
        payment_journals: paymentJournals
      });
      alert("Claim sucessfull");
      hideModal();
    } catch (error) {
      alert(error.data);
    }
  };

  const getTotal = journal => {
    return journal.reduce((total, { journal_amount = 0 }) => {
      total += Number(journal_amount);
      return total;
    }, 0);
  };

  const renderUnclaimed = () => {
    if (!selectedEmployee) return;
    if (selectedEmployee && unclaimedJournals.length === 0) return "No Unclaimed Expenses";
    return (
      <table className="table table-condensed table-claim-form">
        <tbody>
          <tr>
            <th colSpan="5">Unclaimed Expenses</th>
          </tr>
          {unclaimedJournals.map(
            ({ journal_date, journal_amount, trs_desc, trs_code, trs_type, trs_id, checked }, i) => {
              return (
                <tr key={i}>
                  <td>
                    <Link href={`/${trs_type}/${trs_id}`}>{trs_code}</Link>
                  </td>
                  <td>{moment.unix(journal_date).format("DD MMM")}</td>
                  <td>{trs_desc}</td>
                  <td className="right">{journal_amount.toLocaleString()}</td>
                  <td>
                    <input
                      type="checkbox"
                      className="checkbox-claim"
                      checked={checked}
                      onChange={e => {
                        unclaimedJournals[i].checked = e.target.checked;
                        setUnclaimedJournals([...unclaimedJournals]);
                      }}
                    />
                  </td>
                </tr>
              );
            }
          )}
          <tr>
            <th className="right " colSpan="3">
              Total Claimed
            </th>
            <th className="right">{getTotal(unclaimedJournals.filter(({ checked }) => checked)).toLocaleString()}</th>
            <th />
          </tr>
        </tbody>
      </table>
    );
  };

  const renderPayments = () => {
    if (unclaimedJournals.length === 0) return;
    return (
      <table className="table table-condensed">
        <tbody>
          <tr>
            <th colSpan="3">Payments</th>
          </tr>
          {payments.map(({ acc_id, journal_amount, journal_notes }, i) => {
            return (
              <tr key={i}>
                <td>
                  <select
                    className="form-control input-xs"
                    value={acc_id}
                    onChange={e => {
                      payments[i].acc_id = e.target.value;
                      setPayments([...payments]);
                    }}>
                    <option value="">-- Select Account --</option>
                    {accountsPayment.map(({ acc_id, acc_name }, j) => (
                      <option value={acc_id} key={j}>
                        {acc_name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <input
                    className="form-control input-xs"
                    value={journal_notes}
                    onChange={e => {
                      payments[i].journal_notes = e.target.value;
                      setPayments([...payments]);
                    }}
                  />
                </td>
                <td className="right">
                  <input
                    className="form-control input-xs"
                    value={journal_amount}
                    onBlur={() => onBlurInput(i)}
                    onChange={e => {
                      payments[i].journal_amount = e.target.value;
                      setPayments([...payments]);
                    }}
                  />
                </td>
              </tr>
            );
          })}
          <tr>
            <th colSpan="1" />
            <th className="right">Total</th>
            <th className="right">{(getTotal(payments) || "").toLocaleString()}</th>
          </tr>
        </tbody>
      </table>
    );
  };

  if (!accountsPayment || !employees) return "Loading data...";
  return (
    <Modal showModal={showModal} hideModal={hideModal} title="New Claim Journal">
      <form onSubmit={onSubmit} onKeyDown={e => e.code == "Enter" && e.preventDefault()}>
        <div className="modal-body" id="journal-modal">
          <div className="box-body no-padding box-scroll">
            <div style={{ display: "flex" }}>
              <ReactDatePicker
                className="form-control input-sm"
                popperPlacement="bottom-start"
                dateFormat="dd MMM yyyy"
                selected={trs_date}
                onChange={setTrs_date}
              />
              <select
                name="cnt_id"
                className="form-control input-sm"
                required
                value={selectedEmployee}
                onChange={e => setSelectedEmployee(e.target.value)}>
                <option>-- Select Employee --</option>
                {employees.map(({ cnt_id, cnt_name }, i) => (
                  <option key={i} value={cnt_id}>
                    {cnt_name}
                  </option>
                ))}
              </select>
              <input name="trs_desc" placeholder="Enter description" className="form-control input-sm" />
            </div>
            <br />
            {renderUnclaimed()}
            {renderPayments()}
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-secondary" onClick={hideModal}>
            Discard
          </button>
          <button className="btn btn-primary" type="submit">
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
}
