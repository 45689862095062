import httpService from "./httpService";

export function getEmployees() {
  return httpService.get(`${process.env.REACT_APP_API_URL}/employees`);
}

export function getEmployee(cnt_id) {
  return httpService.get(`${process.env.REACT_APP_API_URL}/employee/${cnt_id}`);
}

export function getEmployeesAll() {
  return httpService.get(`${process.env.REACT_APP_API_URL}/employees/all`);
}
