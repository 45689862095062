import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import Modal from "../components/modal";
import { getItemsAll } from "../services/item";
import { createPO } from "../services/purchaseOrder";
import { getSuppliersAll } from "../services/supplier";
import moment from "moment";

export default function PurchaseOrderForm({ showModal, hideModal }) {
  const history = useHistory();
  const [avaiableItems, setAvailableItems] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const availableFees = [
    {
      type: "shipping",
      title: "Shipping Fee"
    },
    {
      type: "packing",
      title: "Packing Fee"
    }
  ];
  const [purchaseOrder, setPurchaseOrder] = useState({
    items: [{}, {}],
    extra_fee: [{}]
  });
  const {
    cnt_id,
    trs_code = "",
    trs_date,
    trs_desc = "",
    trs_amount = "",
    subtotal = "",
    est_delivery_date,
    items,
    extra_fee
  } = purchaseOrder;
  useEffect(async () => {
    try {
      const {
        data: { suppliers }
      } = await getSuppliersAll();
      const {
        data: { items }
      } = await getItemsAll();
      setSuppliers(suppliers);
      setAvailableItems(items);
    } catch (error) {
      alert(error.data);
    }
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    const poData = {
      cnt_id,
      trs_code,
      trs_date: moment(trs_date).unix(),
      trs_desc,
      trs_amount,
      items: items.filter(({ item_id, item_qty, item_price }) => item_id && item_qty && item_price),
      trs_details: [
        {
          type: "subtotal",
          title: "Subtotal",
          value: subtotal
        },
        ...extra_fee
          .filter(({ value }) => value)
          .map(({ type, value }) => {
            return {
              extra_fee,
              value,
              title: availableFees.find(fee => fee.type === type).title
            };
          })
      ]
    };
    try {
      const { data } = await createPO(poData);
      history.push(`/purchase-order/${data.purchaseOrder.trs_id}`);
    } catch (error) {
      alert(error.data);
    }
  };

  const updateTrsAmount = i => {
    const subtotal = items.reduce((subtotal, { item_qty = 0, item_price = 0 }) => {
      subtotal += Number(item_qty) * Number(item_price);
      return subtotal;
    }, 0);
    const fees = extra_fee.reduce((fees, { value = 0 }) => {
      fees += Number(value);
      return fees;
    }, 0);

    setPurchaseOrder({
      ...purchaseOrder,
      trs_amount: subtotal + fees,
      subtotal,
      items: i !== undefined && i === purchaseOrder.items.length - 2 ? [...items, {}] : items
    });
  };

  return (
    <Modal showModal={showModal} hideModal={hideModal} title="New Purchase Order">
      <form onSubmit={onSubmit} onKeyDown={e => e.code == "Enter" && e.preventDefault()}>
        <div className="modal-body" id="po-modal">
          <div className="box-body">
            <div className="row">
              <label className="col-xs-2 control-label">Code</label>
              <div className="col-xs-4">
                <input
                  className="form-control input-xs"
                  value={trs_code}
                  onChange={e => setPurchaseOrder({ ...purchaseOrder, trs_code: e.target.value })}
                />
              </div>
              <label className="col-xs-3 control-label">Order Date</label>
              <div className="col-xs-3">
                <ReactDatePicker
                  className="form-control input-xs"
                  popperPlacement="bottom-end"
                  dateFormat="dd MMM yyyy"
                  selected={trs_date}
                  onChange={trs_date => setPurchaseOrder({ ...purchaseOrder, trs_date })}
                />
              </div>
            </div>
            <div className="row">
              <label className="col-xs-2 control-label">Supplier</label>
              <div className="col-xs-4">
                <select
                  className="form-control input-xs"
                  value={cnt_id}
                  onChange={e => setPurchaseOrder({ ...purchaseOrder, cnt_id: e.target.value })}>
                  <option value="">-- Select Supplier --</option>
                  {suppliers.map(({ cnt_name, cnt_id }, i) => (
                    <option value={cnt_id} key={i}>
                      {cnt_name}
                    </option>
                  ))}
                </select>
              </div>
              <label className="col-xs-3 control-label">Est. Delivery Date</label>
              <div className="col-xs-3">
                <ReactDatePicker
                  className="form-control input-xs"
                  popperPlacement="bottom-end"
                  dateFormat="dd MMM yyyy"
                  selected={est_delivery_date}
                  onChange={est_delivery_date => setPurchaseOrder({ ...purchaseOrder, est_delivery_date })}
                />
              </div>
            </div>
            {/* <div className="row">
              <label className="col-xs-2 control-label">Deposit</label>
              <div className="col-xs-4">
                <input className="form-control input-xs" />
              </div>
              <label className="col-xs-3 control-label">Deposit Due Date</label>
              <div className="col-xs-3">
                <ReactDatePicker
                  className="form-control input-xs"
                  popperPlacement="bottom-end"
                  dateFormat="dd MMM yyyy"
                  selected={depositDueDate}
                  onChange={setDepositDueDate}
                />
              </div>
            </div> */}
            <br />
            <div className="row">
              <label className="col-xs-2 control-label">Description</label>
              <div className="col-xs-10">
                <input
                  className="form-control input-xs text-left"
                  value={trs_desc}
                  onChange={e => {
                    setPurchaseOrder({ ...purchaseOrder, trs_desc: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="box-body no-padding box-scroll">
            <table className="table table-condensed table-item-detail">
              <tbody>
                <tr style={{ backgroundColor: "#f5f5f5" }}>
                  <th>Qty</th>
                  <th>Item</th>
                  <th className="right">Unit Price</th>
                  <th className="right">Total Price</th>
                </tr>
                {items.map(({ item_id, item_qty, item_price }, i) => (
                  <tr key={i}>
                    <td>
                      <input
                        className="form-control input-xs"
                        value={item_qty || ""}
                        onBlur={updateTrsAmount}
                        onChange={e => {
                          purchaseOrder.items[i].item_qty = e.target.value;
                          setPurchaseOrder({ ...purchaseOrder });
                        }}
                      />
                    </td>
                    <td>
                      <select
                        className="form-control input-xs"
                        value={item_id}
                        onChange={e => {
                          purchaseOrder.items[i].item_id = e.target.value;
                          setPurchaseOrder({ ...purchaseOrder });
                        }}>
                        <option value="">-- Select Item --</option>
                        {avaiableItems.map(({ item_name, item_id: _id }, j) => (
                          <option value={_id} key={j}>
                            {item_name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <input
                        className="form-control input-xs"
                        value={item_price || ""}
                        onBlur={() => updateTrsAmount(i)}
                        onChange={e => {
                          purchaseOrder.items[i].item_price = e.target.value;
                          setPurchaseOrder({ ...purchaseOrder });
                        }}
                      />
                    </td>
                    <td className="right">{(item_qty * item_price || "").toLocaleString()}</td>
                  </tr>
                ))}
                <tr>
                  <th />
                  <th />
                  <th className="right">Subtotal</th>
                  <th className="right">{(subtotal || "").toLocaleString()}</th>
                </tr>
                {extra_fee.map(({ type, value }, i) => {
                  return (
                    <tr key={i}>
                      <td />
                      <td />
                      <td className="right">
                        <select
                          className="form-control input-xs"
                          value={type}
                          onChange={e => {
                            purchaseOrder.extra_fee[i].type = e.target.value;
                            setPurchaseOrder({ ...purchaseOrder });
                          }}>
                          <option>-- Extra Fee --</option>
                          {availableFees.map(({ type, title }, j) => (
                            <option key={j} value={type}>
                              {title}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="right">
                        <input
                          className="form-control input-xs"
                          value={value}
                          onChange={e => {
                            purchaseOrder.extra_fee[i].value = e.target.value;
                            setPurchaseOrder({ ...purchaseOrder });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th />
                  <th />
                  <th className="right">Total</th>
                  <th className="right">{(trs_amount || "").toLocaleString()}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-secondary" type="button" onClick={hideModal}>
            Discard
          </button>
          <button className="btn btn-primary" type="submit">
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
}
