import React from "react";
import { useHistory } from "react-router-dom";
export default function Link({ href, children, className = "" }) {
  const history = useHistory();
  const onClick = e => {
    console.log("preventing default");
    e.preventDefault();
    e.stopPropagation();
    history.push(href);
  };
  return (
    <a href={href} className={className} onClick={onClick}>
      {children}
    </a>
  );
}
