import React, { useState } from "react";
export default function CustomerOrder() {
  const [workingList] = useState();
  if (!workingList)
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>Coming soon..</h1>
        </section>
      </div>
    );
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Customer Orders
          <small>{/* Can add description here */}</small>
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="dataTables_wrapper foCO-inline dt-bootstrap">
                  <div className="row">
                    <div className="col-sm-12">
                      <table
                        className="table table-bordered table-striped table-hover dataTable"
                        role="grid"
                        aria-describedby="example1_info">
                        <thead>
                          <tr role="row">
                            <th className="sorting_asc">Code</th>
                            <th className="sorting">Order Date</th>
                            <th className="sorting">Due Date</th>
                            <th className="sorting">Customer</th>
                            <th className="sorting">Description</th>
                            <th className="sorting">Amount</th>
                            <th className="sorting">Paid</th>
                            <th className="sorting">Remaining</th>
                            <th className="sorting">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" className="odd">
                            <td>CO-201103-022</td>
                            <td>05 Nov 2020</td>
                            <td>09 Dec 2020</td>
                            <td className="sorting_1">Eric Clayton</td>
                            <td>Villa di Seminyak</td>
                            <td>10,000,000</td>
                            <td>7,000,000</td>
                            <td>3,000,000</td>
                            <td>In Progress</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>CO-201103-197</td>
                            <td>04 Nov 2020</td>
                            <td>04 Dec 2020</td>
                            <td className="sorting_1">Windy Seminyak</td>
                            <td>Tibetan bowls</td>
                            <td>16,000,000</td>
                            <td>7,000,000</td>
                            <td>9,000,000</td>
                            <td>In Progress</td>
                          </tr>
                          <tr role="row" className="odd" style={{ backgroundColor: "#ffaaaa" }}>
                            <td>CO-201103-004</td>
                            <td>04 Nov 2020</td>
                            <td>23 Jan 2021</td>
                            <td className="sorting_1">Morgan Sayan</td>
                            <td>Rumah Kayu</td>
                            <td>22,000,000</td>
                            <td>22,000,000</td>
                            <td></td>
                            <td>Overdue</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>CO-201103-045</td>
                            <td>04 Nov 2020</td>
                            <td>04 Dec 2020</td>
                            <td className="sorting_1">Yolanda</td>
                            <td>Various terracotta pots</td>
                            <td>10,000,000</td>
                            <td></td>
                            <td>10,000,000</td>
                            <td>Done</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>CO-201143-001</td>
                            <td>17 Jan 2021</td>
                            <td>05 Dec 2021</td>
                            <td className="sorting_1">Eric Clayton</td>
                            <td>Villa di Seminyak</td>
                            <td>13,000,000</td>
                            <td></td>
                            <td>13,000,000</td>
                            <td>Upcoming</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>CO-201103-020</td>
                            <td>09 Feb 2021</td>
                            <td>31 Mar 2021</td>
                            <td className="sorting_1">Windy Seminyak</td>
                            <td>Tibetan bowls</td>
                            <td>31,000,000</td>
                            <td></td>
                            <td></td>
                            <td>Cancelled</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>CO-201103-301</td>
                            <td>08 Apr 2020</td>
                            <td>04 Jun 2020</td>
                            <td className="sorting_1">Morgan Sayan</td>
                            <td>Rumah Kayu</td>
                            <td>10,000,000</td>
                            <td>7,000,000</td>
                            <td>3,000,000</td>
                            <td>Upcoming</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>CO-201453-040</td>
                            <td>04 Nov 2020</td>
                            <td>04 Dec 2020</td>
                            <td className="sorting_1">Yolanda</td>
                            <td>Various terracotta pots</td>
                            <td>10,000,000</td>
                            <td>7,000,000</td>
                            <td>3,000,000</td>
                            <td>In Progress</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>CO-290103-002</td>
                            <td>04 Nov 2020</td>
                            <td>04 Dec 2020</td>
                            <td className="sorting_1">Morgan Sayan</td>
                            <td>Rumah Kayu</td>
                            <td>10,000,000</td>
                            <td>7,000,000</td>
                            <td>3,000,000</td>
                            <td>Done</td>
                          </tr>
                          <tr role="row" className="odd">
                            <td>CO-201103-871</td>
                            <td>04 Nov 2020</td>
                            <td>04 Dec 2020</td>
                            <td className="sorting_1">Yolanda</td>
                            <td>Various terracotta pots</td>
                            <td>10,000,000</td>
                            <td>7,000,000</td>
                            <td>3,000,000</td>
                            <td>Shipped</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
