import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getInventories } from "../services/inventory";

export default function Tool() {
  const [tools, setTools] = useState([]);
  const history = useHistory();
  useEffect(async () => {
    const { data } = await getInventories();
    setTools(data.tools);
  }, []);
  if (!tools.length) return "No data to display";
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Tools
          <small>{/* Can add description here */}</small>
        </h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div id="example1_wrapper" className="dataTables_wrapper form-inline dt-bootstrap">
                  <div className="row">
                    <div className="col-sm-12">
                      <table className="table table-bordered table-striped table-hover dataTable">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Available</th>
                            <th>Remaining Order</th>
                            <th>Disposed</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tools.map(
                            (
                              { item_code, item_id, item_name, item_desc, ordered_qty, received_qty, disposed_qty },
                              i
                            ) => {
                              const className = i % 2 ? "odd" : "even";
                              return (
                                <tr key={i} className={className} onClick={() => history.push(`/inventory/${item_id}`)}>
                                  <td>{i + 1}</td>
                                  <td>{item_code}</td>
                                  <td>{item_name}</td>
                                  <td>{item_desc}</td>
                                  <td>{received_qty || ""}</td>
                                  <td>{ordered_qty - received_qty || ""}</td>
                                  <td>{disposed_qty || ""}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
