import React from "react";
import { useUIContext } from "../context/UIContext";

export default function Toolbar() {
  const { sideBarCollapse, setSideBarCollapse } = useUIContext();
  return (
    <header className="main-header">
      <a href="/" className="logo" onClick={e => e.preventDefault()}>
        <span className="logo-mini">
          <b>P</b>PS
        </span>

        <span className="logo-lg">
          <b>Porpoise</b>
        </span>
      </a>

      <nav className="navbar navbar-static-top">
        <div onClick={() => setSideBarCollapse(!sideBarCollapse)} className="sidebar-toggle" />

        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            <li className="dropdown user user-menu">
              <a href="/" className="dropdown-toggle" onClick={e => e.preventDefault()}>
                <img alt="" src="/images/user.jpg" className="user-image" />
                <span className="hidden-xs">Nina Wiryanto</span>
              </a>
              <ul className="dropdown-menu">
                <li className="user-header">
                  <img alt="" src="/images/user.jpg" className="img-circle" />

                  <p>
                    Nina Wiryanto - Web Developer
                    <small>Member since Nov. 2012</small>
                  </p>
                </li>

                <li className="user-body">
                  <div className="row">
                    <div className="col-xs-4 text-center">
                      <a href="index2.html#">Followers</a>
                    </div>
                    <div className="col-xs-4 text-center">
                      <a href="index2.html#">Sales</a>
                    </div>
                    <div className="col-xs-4 text-center">
                      <a href="index2.html#">Friends</a>
                    </div>
                  </div>
                </li>

                <li className="user-footer">
                  <div className="pull-left">
                    <a href="index2.html#" className="btn btn-default btn-flat">
                      Profile
                    </a>
                  </div>
                  <div className="pull-right">
                    <a href="index2.html#" className="btn btn-default btn-flat">
                      Sign out
                    </a>
                  </div>
                </li>
              </ul>
            </li>

            <li>
              <a href="/" onClick={e => e.preventDefault()}>
                <i className="fa fa-gears"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
