import React, { useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { useHistory } from "react-router-dom";
import { getPOs } from "../services/purchaseOrder";
import PurchaseOrderForm from "../modals/purchaseOrderForm";
import moment from "moment";

export default function PurchaseOrder() {
  const history = useHistory();
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [showModal, hideModal] = useModal(() => <PurchaseOrderForm showModal={showModal} hideModal={hideModal} />);

  useEffect(async () => {
    const { data } = await getPOs();
    setPurchaseOrders(data.purchaseOrders);
  }, []);

  if (!purchaseOrders.length) return "No data to display";

  const onClickSupplierName = (e, href) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(href);
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Purchase Orders
          <small>
            <a
              href="/purchase-orders"
              onClick={e => {
                e.preventDefault();
                showModal();
              }}>
              Create new
            </a>
          </small>
        </h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="dataTables_wrapper foPO-inline dt-bootstrap">
                  <div className="row">
                    <div className="col-sm-12">
                      <table className="table table-bordered table-striped table-hover dataTable">
                        <thead>
                          <tr>
                            <th className="">No</th>
                            <th className="">Code</th>
                            <th className="">Date</th>
                            <th className="">Supplier</th>
                            <th className="">Description</th>
                            {/* <th className="text-right">Ordered</th> */}
                            <th className="text-right">Received</th>
                            <th className="text-right">Remaining</th>
                            {/* <th className="text-right">Amount</th> */}
                            <th className="text-right">Paid</th>
                            <th className="text-right">Remaining</th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchaseOrders.map(
                            (
                              {
                                trs_id,
                                trs_code,
                                trs_date,
                                cnt_name,
                                cnt_id,
                                trs_desc,
                                trs_amount,
                                ordered_qty,
                                received_qty,
                                paid_amount,
                                po_status
                              },
                              i
                            ) => {
                              const className = i % 2 ? "odd" : "even";
                              return (
                                <tr
                                  style={{ backgroundColor: po_status === "closed" ? "" : "#ddffdd" }}
                                  key={i}
                                  className={className}
                                  onClick={() => history.push(`/purchase-order/${trs_id}`)}>
                                  <td>{i + 1}</td>
                                  <td>{trs_code}</td>
                                  <td>{moment.unix(trs_date).format("DD MMM YYYY")}</td>
                                  <td>
                                    {cnt_id && (
                                      <a
                                        href={`/suppliers/${cnt_id}`}
                                        onClick={e => onClickSupplierName(e, `/suppliers/${cnt_id}`)}>
                                        {cnt_name}
                                      </a>
                                    )}
                                  </td>
                                  <td>{trs_desc}</td>
                                  {/* <td className="text-right">{(ordered_qty || "").toLocaleString()}</td> */}
                                  <td className="text-right">{(received_qty || "").toLocaleString()}</td>
                                  <td className="text-right">{(ordered_qty - received_qty || "").toLocaleString()}</td>
                                  {/* <td className="text-right">{trs_amount.toLocaleString()}</td> */}
                                  <td className="text-right">{(paid_amount || "").toLocaleString()}</td>
                                  <td className="text-right">{(trs_amount - paid_amount || "").toLocaleString()}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
