import React, { useState } from "react";
import Th from "../components/th";

const header = ["No", "Date", "Transactions", "Customers", "Items", "Total Quantity"];
const body = [
  [1, "06 Nov 2020", "CO-20200617-01, CO-20200617-01", "Vanya, Yolanda", "Vas Rotan, Vas Muka, Vas Angg...", 167],
  [2, "07 Nov 2020", "CO-20200617-01, CO-20200617-01", "Fenia", "Table Console, Puzzle Table...", 549],
  [3, "08 Dec 2020", "CO-20200617-01, CO-20200617-01", "Wayan, Kadek", "Barnacle", 125],
  [4, "09 Nov 2020", "CO-20200617-01, CO-20200617-01", "Iwan, Leo, Bimo", "Blown Glass", 16],
  [4, "09 Jan 2021", "CO-20210617-01, CO-20210617-01", "Windy", "Rumah Kayu", 167],
  [5, "10 Mar 2021", "CO-20210617-01, CO-20210617-01", "Morgan, Windy", "Wooden Tray", 402],
  [6, "11 Apr 2021", "CO-20210617-01, CO-20210617-01", "Marina", "Patung Sumba", 102],
  [7, "14 Apr 2021", "CO-20210617-01, CO-20210617-01", "Memet", "Tribal Wall Hanger", 30],
  [8, "16 May 2021", "CO-20210617-01, CO-20210617-01", "Bill, Mikkel", "Coconut Shell Buttons", 856],
  [9, "19 Jun 2021", "CO-20210617-01, CO-20210617-01", "Eliza", "Dream Catchers", 19],
  [10, "40 Aug 2021", "CO-20210617-01, CO-20210617-01", "Dinny, Sansan", "Macrame", 856]
];
export default function Shipping() {
  const [workingList] = useState();
  if (!workingList)
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>Coming soon..</h1>
        </section>
      </div>
    );
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>
          Pengiriman/Pengambilan
          <small>{/* Can add description here */}</small>
        </h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="dataTables_wrapper foCO-inline dt-bootstrap">
                  <div className="row">
                    <div className="col-sm-12">
                      <table className="table table-bordered table-striped table-hover dataTable">
                        <thead>
                          <tr role="row">
                            {header.map((title, i) => (
                              <Th key={i}>{title}</Th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {body.map((row, i) => {
                            return (
                              <tr key={i} className={i % 2 === 0 ? "odd" : "even"}>
                                {row.map((content, j) => (
                                  <td key={j}>{content}</td>
                                ))}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
