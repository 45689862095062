import axios from "axios";

axios.interceptors.response.use(
  res => res,
  error => {
    console.log("error", error.toString());
    if (error.response.status === 440) {
      window.location.reload();
      return;
    } else return Promise.reject(error.response || { ok: 0, data: error.toString() });
  }
);

axios.interceptors.request.use(config => {
  const jwt = localStorage.getItem("jwt");
  if (jwt) config.headers.common["Authorization"] = jwt;
  return config;
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  options: axios.options,
  axios
};
