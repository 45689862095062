import React, { createContext, useContext, useState } from "react";

const UIContext = createContext();

export function useUIContext() {
  return useContext(UIContext);
}
export function UIContextProvider(props) {
  const [sideBarCollapse, setSideBarCollapse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <UIContext.Provider
      value={{
        sideBarCollapse,
        setSideBarCollapse,
        isLoading,
        setIsLoading
      }}>
      {props.children}
    </UIContext.Provider>
  );
}
